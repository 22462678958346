import ColorFilter from "components/collection-filter/ColorFilter";
import FiltersMobile from "components/collection-filter/FiltersMobile";
import PriceFilter from "components/collection-filter/PriceFilter";
import ProductTypeFilter from "components/collection-filter/ProductTypeFilter";
import SortingSelector from "components/collection-filter/SortingSelector";
import SortingSelectorMobile from "components/collection-filter/SortingSelectorMobile";
import CollectionHeader from "components/CollectionHeader";
import PaginationNavigation from "components/PaginationNavigation";
import ProductList from "components/ProductList";
import SEO from "components/SEO";
import SideBarPanel from "components/SideBarPanel";
import Wrapper from "components/Wrapper";
import { graphql, Link } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { DatoCmsProduct, ShopifyProduct } from "graphql-typings";
import { useTranslation } from "hooks/useTranslation";
import { isEmpty } from "lodash";
import qs from "querystring";
import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { computePathFromLocalizedData } from "utils/common/navigation";
import { universalBtoa } from "utils/product";

interface Props {
  data: {
    datoCmsCollection: {
      title: string;
      description: string;
      descriptionNode: {
        childMarkdownRemark: { html: string };
      };
      seo: any;
      relatedCollections: {
        title: string;
        handle: string;
      }[];
    };
    shopifyCollection: {
      image: { gatsbyImageData: IGatsbyImageData; altText?: string };
    };
    allDatoCmsProduct: { nodes: any[] };
    allShopifyProduct: { nodes: any[] };
  };
  pageContext: {
    pageNumber: number;
    productsPerPage: number;
    numberOfPages: number;
    basePath: string;
    locale: string;
    defaultLocale: string;
    allPathLocales: string[];
    productIds: string[];
    composableCollection?: {
      path: string;
      callToAction: string;
    };
    colorFilterOptions: any[];
    priceFilterOptions: any[];
    productTypeFilterOptions: any[];
    sortingSelectorOptions: any[];
    defaultOrdering: string;
    orderingById: {
      [id: string]: {
        title: number;
        price: number;
        bestSelling: number;
        createdAt: number;
        manual?: number;
      };
    };
  };
}

/**
 * Collections page wrapper
 * Just to allow useContext usage inside
 */
const CollectionPage: React.SFC<Props> = ({ data, pageContext }) => {
  return <Collection data={data} pageContext={pageContext} />;
};

/**
 * Generic collection template
 */
const Collection: React.SFC<Props> = ({ data, pageContext }) => {
  const {
    locale,
    defaultLocale,
    pageNumber,
    numberOfPages,
    basePath,
    productIds,
    composableCollection,
    colorFilterOptions,
    priceFilterOptions,
    productTypeFilterOptions,
    sortingSelectorOptions,
    defaultOrdering,
    orderingById,
  } = pageContext;

  const { formatMessage } = useTranslation();

  const {
    datoCmsCollection: collection,
    shopifyCollection: { image: collectionImage },
    allDatoCmsProduct: { nodes: datocmsProducts },
    allShopifyProduct: { nodes: shopifyProducts },
  } = data;

  const seo = collection.seo;

  const initialProducts = productIds.map((productId) => {
    const datocmsProduct = datocmsProducts.find(
      (product) => product.shopifyId === universalBtoa(productId)
    );
    const shopifyProduct = shopifyProducts.find(
      (product) => product.storefrontId === productId
    );
    const ordering = orderingById[productId];
    return {
      dato: datocmsProduct,
      shopify: shopifyProduct,
      page: pageNumber,
      ordering,
    };
  });

  const [products, setProducts] = useState(initialProducts);
  const [filteredProducts, setFilteredProducts] = useState(initialProducts);
  const [filter, setFilter] = useState();
  const [sorting, setSorting] = useState();
  const [higherLoadedPage, setHigherLoadedPage] = useState(pageNumber);
  const [loadingMoreProducts, setLoadingMoreProducts] = useState(false);
  const [hasPreviousProducts, setHasPreviousProducts] = useState(
    pageNumber !== 1
  );
  const [loadingPreviousProducts, setLoadingPreviousProducts] = useState(false);
  const [loadingAllProducts, setLoadingAllProducts] = useState(false);
  const [isFilterSidebarOpen, setFilterSidebarOpen] = useState(false);
  const [isSortingSidebarOpen, setSortingSidebarOpen] = useState(false);

  useEffect(() => {
    const location = window.location;
    const queryString = location && location.search.replace("?", "");
    const queryParams = qs.parse(queryString);

    if (queryParams) {
      if (
        queryParams.price ||
        queryParams.color ||
        queryParams.productType ||
        queryParams.sorting
      ) {
        requestAllProducts();
      }
      let filter = {};
      if (queryParams.price) {
        filter = {
          ...filter,
          price:
            typeof queryParams.price === "string"
              ? [queryParams.price]
              : [...queryParams.price],
        };
      }
      if (queryParams.color) {
        filter = {
          ...filter,
          color:
            typeof queryParams.color === "string"
              ? [queryParams.color]
              : [...queryParams.color],
        };
      }
      if (queryParams.productType) {
        filter = {
          ...filter,
          productType:
            typeof queryParams.productType === "string"
              ? [queryParams.productType]
              : [...queryParams.productType],
        };
      }
      if (!isEmpty(filter)) {
        setFilter(filter);
      }
      if (queryParams.sorting) {
        setSorting(queryParams.sorting);
      }
    }
  }, []);

  useEffect(() => {
    if ((filter || sorting) && !loadingAllProducts) {
      const fetchAndFilter = async () => {
        await requestAllProducts();
        replaceBrowserHistory(computeFilterQueryString(filter, sorting));
        const sortedProducts = sortProducts(sorting, products);
        const filteredProducts = filterProducts(filter, sortedProducts);
        setFilteredProducts(filteredProducts);
      };
      fetchAndFilter();
    } else {
      setFilteredProducts(products);
    }
  }, [filter, sorting, products]);

  const requestPreviousProducts = async () => {
    setLoadingPreviousProducts(true);
    const promises = [];
    for (let i = 1; i < pageNumber; i++) {
      const path = `${basePath}${i > 1 ? `/${i}` : ``}`;
      const fileName =
        path.substr(1).replace(new RegExp("/", "g"), "-") + ".json";
      const promise = fetch(`/paginationJson/${fileName}`)
        .then((response) => response.json())
        .then((json) => {
          const newProducts = json.productIds.map((productId: string) => {
            const datocmsProduct = json.data.allDatoCmsProduct.nodes.find(
              (product: DatoCmsProduct) => product.shopifyId === btoa(productId)
            );
            const shopifyProduct = json.data.allShopifyProduct.nodes.find(
              (product: ShopifyProduct) => product.storefrontId === productId
            );
            const ordering = orderingById[productId];
            return {
              dato: datocmsProduct,
              shopify: shopifyProduct,
              page: i,
              ordering,
            };
          });
          return newProducts;
        });
      promises.push(promise);
    }
    const results = await Promise.all(promises);
    setLoadingPreviousProducts(false);
    setHasPreviousProducts(false);
    setProducts((products) => [
      ...results.reduce((acc, elem) => [...acc, ...elem], []),
      ...products,
    ]);
  };

  const requestMoreProducts = () => {
    if (!loadingMoreProducts && higherLoadedPage < numberOfPages) {
      setLoadingMoreProducts(true);
      const path = `${basePath}/${higherLoadedPage + 1}`;
      const fileName =
        path.substr(1).replace(new RegExp("/", "g"), "-") + ".json";
      fetch(`/paginationJson/${fileName}`)
        .then((response) => response.json())
        .then((json) => {
          const newProducts = json.productIds.map((productId: string) => {
            const datocmsProduct = json.data.allDatoCmsProduct.nodes.find(
              (product: DatoCmsProduct) => product.shopifyId === btoa(productId)
            );
            const shopifyProduct = json.data.allShopifyProduct.nodes.find(
              (product: ShopifyProduct) => product.storefrontId === productId
            );
            const ordering = orderingById[productId];
            return {
              dato: datocmsProduct,
              shopify: shopifyProduct,
              page: higherLoadedPage + 1,
              ordering,
            };
          });
          setProducts([...products, ...newProducts]);
          setHigherLoadedPage(higherLoadedPage + 1);
          setLoadingMoreProducts(false);
        });
    }
  };

  const requestAllProducts = async () => {
    if (
      !loadingMoreProducts &&
      (higherLoadedPage < numberOfPages || hasPreviousProducts)
    ) {
      setLoadingAllProducts(true);
      const promises = [];
      for (let i = 1; i <= numberOfPages; i++) {
        const path = `${basePath}${i > 1 ? `/${i}` : ``}`;
        const fileName =
          path.substr(1).replace(new RegExp("/", "g"), "-") + ".json";
        const promise = fetch(`/paginationJson/${fileName}`)
          .then((response) => response.json())
          .then((json) => {
            const newProducts = json.productIds.map((productId: string) => {
              const datocmsProduct = json.data.allDatoCmsProduct.nodes.find(
                (product: DatoCmsProduct) =>
                  product.shopifyId === btoa(productId)
              );
              const shopifyProduct = json.data.allShopifyProduct.nodes.find(
                (product: ShopifyProduct) => product.storefrontId === productId
              );
              const ordering = orderingById[productId];
              return {
                dato: datocmsProduct,
                shopify: shopifyProduct,
                page: i,
                ordering,
              };
            });
            return newProducts;
          });
        promises.push(promise);
      }
      const results = await Promise.all(promises);
      setLoadingAllProducts(false);
      setHigherLoadedPage(numberOfPages);
      setHasPreviousProducts(false);
      setProducts(results.reduce((acc, elem) => [...acc, ...elem], []));
    }
  };

  const onScrollGetVisibleProductPage = (page: number) => {
    if (
      !(filter || sorting) &&
      ((location.pathname.replace(`${basePath}/`, "") === "" && page !== 1) ||
        (!Number.isNaN(
          parseFloat(
            location.pathname.replace(`${basePath}/`, "").replace("/", "")
          )
        ) &&
          parseFloat(
            location.pathname.replace(`${basePath}/`, "").replace("/", "")
          ) !== page))
    ) {
      replaceBrowserHistory(`${basePath}${page === 1 ? `/` : `/${page}/`}`);
    }
  };

  const links = useMemo(() => {
    const links = [];
    if (pageNumber === 1) {
      links.push({
        rel: "canonical",
        href: `https://ruedesmille.com${basePath}${
          pageNumber > 1 ? `/${pageNumber}/` : "/"
        }`,
      });
    }
    if (pageNumber > 1) {
      links.push({
        rel: "prev",
        href: `https://ruedesmille.com${basePath}${
          pageNumber > 2 ? `/${pageNumber - 1}/` : "/"
        }`,
      });
    }
    if (pageNumber < numberOfPages) {
      links.push({
        rel: "next",
        href: `https://ruedesmille.com${basePath}/${pageNumber + 1}/`,
      });
    }
    return links;
  }, [basePath, pageNumber, numberOfPages]);

  return (
    <>
      <Wrapper>
        <SEO
          title={(seo && seo.title) || collection.title}
          titleSuffix={seo && seo.title ? "" : undefined}
          description={(seo && seo.description) || collection.description}
          imageUrl={
            (seo && seo.image && seo.image.url) ||
            (collectionImage && collectionImage.src)
          }
          link={links}
          meta={
            pageNumber > 1
              ? [{ name: "robots", content: "noindex, follow" }]
              : []
          }
        />
        <CollectionHeader
          title={collection.title}
          description={collection.descriptionNode.childMarkdownRemark.html}
          image={collectionImage}
        />

        {productIds?.length > 0 && (
          <>
            <SortingSelectorContainer>
              <SortingSelector
                options={sortingSelectorOptions}
                activeOption={sorting || defaultOrdering}
                setSorting={setSorting}
              />
            </SortingSelectorContainer>
            <MobileFilters>
              {(colorFilterOptions ||
                priceFilterOptions ||
                productTypeFilterOptions) && (
                <>
                  <FiltersTrigger onClick={() => setFilterSidebarOpen(true)}>
                    {formatMessage({ id: "filter.label" })}
                  </FiltersTrigger>
                  <SideBarPanel
                    handleClose={() => setFilterSidebarOpen(false)}
                    isOpen={isFilterSidebarOpen}
                    position="left"
                  >
                    {isFilterSidebarOpen && (
                      <FiltersMobile
                        colorOptions={colorFilterOptions}
                        colorActiveOptions={(filter && filter.color) || []}
                        priceOptions={priceFilterOptions}
                        priceActiveOptions={(filter && filter.price) || []}
                        productTypeOptions={productTypeFilterOptions}
                        productTypeActiveOptions={filter && filter.productType}
                        relatedCollections={collection.relatedCollections}
                        setFilter={(filter) => {
                          setFilter(filter);
                          setFilterSidebarOpen(false);
                        }}
                      />
                    )}
                  </SideBarPanel>
                </>
              )}
              <SortingTrigger onClick={() => setSortingSidebarOpen(true)}>
                {formatMessage({ id: "sortingSelector.title" })}
              </SortingTrigger>
              <SideBarPanel
                handleClose={() => setSortingSidebarOpen(false)}
                isOpen={isSortingSidebarOpen}
                position="right"
              >
                {isSortingSidebarOpen && (
                  <SortingSelectorMobile
                    options={sortingSelectorOptions}
                    activeOption={sorting || defaultOrdering}
                    setSorting={(sorting) => {
                      setSorting(sorting);
                      setSortingSidebarOpen(false);
                    }}
                  />
                )}
              </SideBarPanel>
            </MobileFilters>
          </>
        )}
      </Wrapper>
      <MainContainer>
        {productIds?.length > 0 ? (
          <>
            <Filters>
              <LabelFilters>
                {formatMessage({ id: "filter.label" })}:
              </LabelFilters>

              {collection.relatedCollections.length > 0 && (
                <RelatedCollections>
                  {collection.relatedCollections.map((relatedCollection) => {
                    const path = computePathFromLocalizedData(
                      locale,
                      defaultLocale,
                      "DatoCmsCollection",
                      relatedCollection.handle
                    );
                    return (
                      <CollectionBadge key={relatedCollection.handle}>
                        <Link to={path}>{relatedCollection.title}</Link>
                      </CollectionBadge>
                    );
                  })}
                </RelatedCollections>
              )}
              {colorFilterOptions && (
                <>
                  <ColorFilter
                    options={colorFilterOptions}
                    activeOptions={(filter && filter.color) || []}
                    setFilter={(value) =>
                      setFilter((filter: any) => {
                        const index =
                          filter && filter.color && filter.color.indexOf(value);
                        if (index >= 0) {
                          const color = filter.color;
                          color.splice(index, 1);
                          return { ...filter, color };
                        }
                        (window as any).dataLayer.push({
                          event: "gaEvent",
                          eventCategory: "Add Filter",
                          eventAction: "Color",
                          label: value,
                        });
                        return {
                          ...filter,
                          color: [...((filter && filter.color) || []), value],
                        };
                      })
                    }
                  />
                </>
              )}
              {priceFilterOptions && (
                <>
                  <PriceFilter
                    options={priceFilterOptions}
                    activeOptions={(filter && filter.price) || []}
                    setFilter={(value) =>
                      setFilter((filter: any) => {
                        const index =
                          filter && filter.price && filter.price.indexOf(value);
                        if (index >= 0) {
                          const price = filter.price;
                          price.splice(index, 1);
                          return { ...filter, price };
                        }
                        (window as any).dataLayer.push({
                          event: "gaEvent",
                          eventCategory: "Add Filter",
                          eventAction: "Price",
                          label: value,
                        });
                        return {
                          ...filter,
                          price: [...((filter && filter.price) || []), value],
                        };
                      })
                    }
                  />
                </>
              )}
              {productTypeFilterOptions && (
                <>
                  <ProductTypeFilter
                    options={productTypeFilterOptions}
                    activeOptions={(filter && filter.productType) || []}
                    setFilter={(value) =>
                      setFilter((filter: any) => {
                        const index =
                          filter &&
                          filter.productType &&
                          filter.productType.indexOf(value);
                        if (index >= 0) {
                          const productType = filter.productType;
                          productType.splice(index, 1);
                          return { ...filter, productType };
                        }
                        (window as any).dataLayer.push({
                          event: "gaEvent",
                          eventCategory: "Add Filter",
                          eventAction: "Product type",
                          label: value,
                        });
                        return {
                          ...filter,
                          productType: [
                            ...((filter && filter.productType) || []),
                            value,
                          ],
                        };
                      })
                    }
                  />
                </>
              )}
            </Filters>
            <Wrapper>
              <ProductList
                products={filteredProducts}
                hasPreviousProducts={hasPreviousProducts}
                loadPreviousProductsLabel={formatMessage(
                  { id: "infiniteScroll.loadPrevious" },
                  { pageNumber }
                )}
                loadingPreviousProducts={loadingPreviousProducts}
                loadingMoreProducts={loadingMoreProducts}
                loadingAllProducts={loadingAllProducts}
                renderActionCard={
                  composableCollection
                    ? () => (
                        <ActionCard>
                          <Link to={composableCollection.path}>
                            {composableCollection.callToAction}
                            <FrameTop />
                            <FrameBottom />
                          </Link>
                        </ActionCard>
                      )
                    : undefined
                }
                requestPreviousProducts={requestPreviousProducts}
                requestMoreProducts={requestMoreProducts}
                onScrollGetVisibleProductPage={onScrollGetVisibleProductPage}
              />
              <noscript>
                <PaginationNavigation
                  isFirst={pageNumber === 1}
                  isLast={pageNumber === numberOfPages}
                  prevPage={`${basePath}${
                    pageNumber > 2 ? `/${pageNumber - 1}/` : "/"
                  }`}
                  nextPage={`${basePath}/${pageNumber + 1}/`}
                  prevPageMessage={formatMessage(
                    { id: "button.goToPage" },
                    { page: pageNumber - 1 }
                  )}
                  nextPageMessage={formatMessage(
                    { id: "button.goToPage" },
                    { page: pageNumber + 1 }
                  )}
                />
              </noscript>
            </Wrapper>
          </>
        ) : (
          <EmptyCollectionWrapper>
            <EmptyCollectionTitle>
              <FormattedMessage id="collection.noProductsTitle" />
            </EmptyCollectionTitle>
            <EmptyCollectionSubtitle>
              <FormattedMessage
                id="collection.noProductsSubtitle"
                values={{
                  homePage: (
                    <Link to={locale === defaultLocale ? `/` : `/${locale}/`}>
                      homepage
                    </Link>
                  ),
                }}
              />
            </EmptyCollectionSubtitle>
          </EmptyCollectionWrapper>
        )}
      </MainContainer>
    </>
  );
};

const sortProducts = (sorting: string, products: any[]) => {
  return !sorting
    ? products
    : products.sort((a, b) => {
        switch (sorting) {
          case "featured":
            return a.ordering.manual - b.ordering.manual;
          case "bestSelling":
            return a.ordering.bestSelling - b.ordering.bestSelling;
          case "ascendingPrice":
            return a.ordering.price - b.ordering.price;
          case "descendingPrice":
            return b.ordering.price - a.ordering.price;
          case "mostRecent":
            return b.ordering.createdAt - a.ordering.createdAt;
          case "leastRecent":
            return a.ordering.createdAt - b.ordering.createdAt;
          default:
            return 1;
        }
      });
};

const filterProducts = (filter: any, products: any[]) => {
  return products.filter((product) => {
    if (filter) {
      let allow = true;
      if (filter.price && filter.price.length > 0) {
        let isValidPrice = false;
        for (let i = 0; i < filter.price.length; i++) {
          const price = product.shopify.variants[0].price;
          const lowerValue = Number(
            filter.price[i].split("-")[0].replace("+", "")
          );
          const upperValue = Number(filter.price[i].split("-")[1]) || undefined;
          if (
            (upperValue && price >= lowerValue && price < upperValue) ||
            (!upperValue && price >= lowerValue)
          ) {
            isValidPrice = true;
            break;
          }
        }
        allow = allow && isValidPrice;
      }
      if (filter.color && filter.color.length > 0) {
        if (!product.shopify.tags) {
          return false;
        }
        const colorTags = product.shopify.tags
          .filter((el: string) => el.includes("color:"))
          .map((el: string) => el.replace("color:", ""));
        allow =
          allow &&
          Boolean(
            colorTags.filter(
              (availableColor: string) =>
                filter.color.indexOf(availableColor) > -1
            ).length > 0
          );
      }
      if (filter.productType && filter.productType.length > 0) {
        allow =
          allow &&
          filter.productType.findIndex(
            (productType: any) =>
              productType === product.dato.category.shopifyHandle
          ) >= 0;
      }
      return allow;
    } else {
      return products;
    }
  });
};

const replaceBrowserHistory = (url: string) => {
  history.replaceState({}, "", url);
  const pagePath = `${location.pathname}${location.search}`;
  (window as any).dataLayer.push({
    event: "Pageview",
    pagePath: pagePath,
  });
};

const computeFilterQueryString = (
  filter: { color: string[]; price: string[]; productType: string[] },
  sorting: string
) => {
  let queryString = "";
  if (filter && filter.color && filter.color.length > 0) {
    queryString += filter.color.reduce((acc: string, elem: any) => {
      acc +=
        acc.length > 0
          ? `&color=${encodeURIComponent(elem)}`
          : `?color=${encodeURIComponent(elem)}`;
      return acc;
    }, "");
  }
  if (filter && filter.price && filter.price.length > 0) {
    queryString += filter.price.reduce((acc: string, elem: any) => {
      acc +=
        queryString.length + acc.length > 0
          ? `&price=${encodeURIComponent(elem)}`
          : `?price=${encodeURIComponent(elem)}`;
      return acc;
    }, "");
  }
  if (filter && filter.productType && filter.productType.length > 0) {
    queryString += filter.productType.reduce((acc: string, elem: any) => {
      acc +=
        queryString.length + acc.length > 0
          ? `&productType=${encodeURIComponent(elem)}`
          : `?productType=${encodeURIComponent(elem)}`;
      return acc;
    }, "");
  }
  if (sorting) {
    queryString +=
      queryString.length > 0
        ? `&sorting=${encodeURIComponent(sorting)}`
        : `?sorting=${encodeURIComponent(sorting)}`;
  }
  return queryString;
};

export const query = graphql`
  query collectionQuery(
    $locale: String
    $shopifyHandle: String
    $productIds: [String]
    $storefrontProductIds: [String]
  ) {
    datoCmsCollection(
      shopifyHandle: { eq: $shopifyHandle }
      locale: { eq: $locale }
    ) {
      title
      description
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      relatedCollections {
        handle
        title
      }
    }
    shopifyCollection(handle: { eq: $shopifyHandle }) {
      image {
        altText
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    allDatoCmsProduct(
      filter: {
        shopifyId: { in: $storefrontProductIds }
        locale: { eq: $locale }
      }
    ) {
      nodes {
        __typename
        shopifyId
        title
        handle
        featured
        category {
          shopifyHandle
          handle
          _allHandleLocales {
            locale
            value
          }
        }
      }
    }
    allShopifyProduct(
      sort: { fields: [handle], order: ASC }
      filter: { storefrontId: { in: $productIds } }
    ) {
      nodes {
        shopifyId
        storefrontId
        handle
        description
        title
        media {
          id
          shopifyId
          mediaContentType
          ... on ShopifyMediaImage {
            image {
              originalSrc
              altText
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        variants {
          shopifyId
          storefrontId
          title
          compareAtPrice
          price
          media {
            id
            shopifyId
            mediaContentType
            ... on ShopifyMediaImage {
              image {
                originalSrc
                altText
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          selectedOptions {
            name
            value
          }
        }
        tags
      }
    }
  }
`;

const FrameTop = styled.div``;

const FrameBottom = styled.div``;

const ActionCard = styled.div`
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  flex: 0 0 25%;
  width: 25%;
  display: flex;

  @media (max-width: 1000px) {
    flex: 0 0 33.33%;
    width: 33.33%;
  }
  @media (max-width: 750px) {
    flex: 0 0 50%;
    width: 50%;
  }
  @media (max-width: 500px) {
    flex: 0 0 100%;
    width: 100%;
  }
  a {
    border: 20px solid ${({ theme }) => theme.colors.background2};
    height: 100%;
    min-height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    letter-spacing: 0.1em;
    color: ${({ theme }) => theme.colors.main};
    text-transform: uppercase;
    padding: 30px;
    font-weight: 600;
    text-align: center;
    line-height: 2em;
    text-decoration: none;
    transition: 0.3s all;
    position: relative;
    ${FrameTop}::before, ${FrameTop}::after,
  ${FrameBottom}::before, ${FrameBottom}::after {
      content: "";
      position: absolute;
      background: ${({ theme }) => theme.colors.main};
      transition: all 0.5s;
    }
    ${FrameTop}::before,${FrameBottom}::before {
      left: -20px;
      top: -20px;
    }
    ${FrameTop}::after,${FrameBottom}::after {
      right: -20px;
      bottom: -20px;
    }
    ${FrameTop}::before,${FrameTop}::after {
      height: 20px;
      width: 0;
    }
    ${FrameBottom}::before, ${FrameBottom}::after {
      width: 20px;
      height: 0;
    }
    &:hover ${FrameTop}::before, &:hover ${FrameTop}::after {
      width: calc(100% + 40px);
    }
    &:hover ${FrameBottom}::before, &:hover ${FrameBottom}::after {
      height: calc(100% + 40px);
    }
  }
`;

const Filters = styled.div`
  position: absolute;
  padding: 0 20px;
  top: -20px;
  width: 260px;
  flex: none;
  @media (max-width: 1700px) {
    position: relative;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;
const SortingSelectorContainer = styled.div`
  display: flex;
  padding-top: 70px;
  @media (max-width: 960px) {
    display: none;
  }
`;

const MainContainer = styled.div`
  display: flex;
  position: relative;
`;

const MobileFilters = styled.div`
  display: none;
  @media (max-width: 960px) {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
`;

const FiltersTrigger = styled.button`
  font-size: 13px;
  letter-spacing: 0.03em;
  color: #706f6f;
  padding: 10px 0;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  font-family: soleil, sans-serif;
`;

const SortingTrigger = styled.button`
  font-size: 13px;
  letter-spacing: 0.03em;
  color: #706f6f;
  text-transform: uppercase;
  padding: 10px 0;
  background-color: transparent;
  border: none;
  font-family: soleil, sans-serif;
`;

const LabelFilters = styled.div`
  font-size: 13px;
  letter-spacing: 0.03em;
  color: #706f6f;
  padding-bottom: 20px;
  text-transform: uppercase;
`;

const RelatedCollections = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
`;

const CollectionBadge = styled.div`
  padding-bottom: 6px;
  &:last-child {
    padding-bottom: 0px;
  }
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.main};
    padding: 0px 8px 1px 8px;
    display: inline-block;
    font-size: 13px;
    background-color: ${({ theme }) => theme.colors.background2};
    border-radius: 100px;
    border: 1px solid ${({ theme }) => theme.colors.brand100};
    transition: 0.3s all;
    @media (max-width: 500px) {
      font-size: 12px;
    }
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.brand100};
      background-color: ${({ theme }) => theme.colors.brand100};
    }
  }
`;

const EmptyCollectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 130px;
`;

const EmptyCollectionTitle = styled.h1`
  text-align: center;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
`;

const EmptyCollectionSubtitle = styled.div`
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.8em;
  margin: 0;
  padding-bottom: 15px;
  a {
    color: ${({ theme }) => theme.colors.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default CollectionPage;
